
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@mixin transition($transition-property, $transition-time, $method) {
    -webkit-transition: $transition-property $transition-time $method;
    -moz-transition: $transition-property $transition-time $method;
    -ms-transition: $transition-property $transition-time $method;
    -o-transition: $transition-property $transition-time $method;
    transition: $transition-property $transition-time $method;
}
html, body {
    background-color: #fff;
    color: #111;
    font-family: 'Raleway', sans-serif;
    font-weight: 100;
    height: 100vh;
    margin: 0;
}
body {
    width: 100%;
    height: 100%;
    margin: 0px;
    overflow-x: hidden;
}


.bounce {
    position: absolute;
    bottom:3%;
    left:49%;
    animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    -moz-animation: bounce 2s infinite;
    -o-animation: bounce 2s infinite;

}
img.j-icon-chev-down{
    width:38px;
    height:auto;
    padding:5px;
    margin: 3px 0px 10px 0px;
    @include transition(all, 0.2s, ease-in-out);
    position:relative;
    top:0px;
    &:hover{
        cursor: pointer;
        img.j-icon-chev-down{
            top:5px;
        }
    }

}

.full-height {
    height: 100vh;
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.position-ref {
    position: relative;
}

.top-right {
    position: absolute;
    right: 10px;
    top: 18px;
}

#main {
    position: relative;
    background: white;
    height: auto;
    min-height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    resize: both;
    overflow: auto;
}
.home {

    background: white;
    color: white;
    width: 90%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 48%;
    left: 50%;
}

.title {
    font-size: 5em;
    color: #f48728;
}
.center{
    text-align: center;

}
.m-b-md {
    margin-bottom: 30px;
}
.links > a {
    color: #636b6f;
    padding: 0 25px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
}


.links > a {
    color: #636b6f;
    padding: 0 25px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
}

body
{
    margin: 0;
    padding: 0;

    /* make it look decent enough */
    background: #fff;
    color: #111;
}

a
{
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;
}

a:hover
{
    color: tomato;
}

.vert .carousel-item-next.carousel-item-left,
.vert .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.vert .carousel-item-next,
.vert .active.carousel-item-right {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100% 0);
}

.vert .carousel-item-prev,
.vert .active.carousel-item-left {
-webkit-transform: translate3d(0,-100%, 0);
        transform: translate3d(0,-100%, 0);
}

#menuToggle
{
    display: block;
    position: relative;
    float:right;
    margin-right: 100px;
    top: 50px;
    left: 50px;

    z-index: 1;

    -webkit-user-select: none;
    user-select: none;
}

#menuToggle input
{
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span
{
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #cdcdcd;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    opacity 0.55s ease;
}

#menuToggle span:first-child
{
    transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
    transform-origin: 0% 100%;
}

/*
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span
{
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3)
{
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2)
{
    opacity: 1;
    transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu
{
    position: absolute;
    width: 300px;
    margin: -100px 0 0 -230px;
    padding: 50px;
    padding-top: 125px;
    text-align: right;
    background: #fff;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(100%, 0);

    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
    padding: 10px 0;
    font-size: 22px;
}

/*
 * And let's fade it in from the left
 */
#menuToggle input:checked ~ ul
{
    transform: scale(1.0, 1.0);
    opacity: 1;
}


#portfolio{
    position: relative;
    background: white;
    height: auto;
    min-height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 150px;
    resize: both;
    overflow: auto;
    color: #111;
}
.grid-item {
    float: left;
    margin-left: 25px;
    width: 30%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    img{
        width:100%;
    }
}

/* fluid 5 columns */
.grid-sizer,
.grid-item { width: 30%; }

.fancybox-slide--iframe .fancybox-content {
    width  : 75%;
    height : 100%;
    max-width  : 75%;
    max-height : 100%;
    margin: 0;
}

label {
    color: #636b6f;
}

#contact {
    position: relative;
    background: white;
    height: auto;
    min-height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    resize: both;
    overflow: auto;
    color: #111;
}

.margin-top {
    margin-top: 20px;
}

.btn-orange {
    background-color: #F5913A;
}

.slideout-menu {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 256px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    display: none;
}

.slideout-panel {
    position: relative;
    z-index: 1;
    will-change: transform;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
    overflow: hidden;
}

.slideout-open .slideout-menu {
    display: block;
}

.form-control:focus {
    border-color: #F48728;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(244, 135, 40, 0.6);

}

.content {
    font-family: Futura, "Trebuchet MS", Arial, sans-serif;
}

.header {
    margin: 0 auto;
}

.header h1 {
    color: white;
}

.contact-us {
    margin: 0 auto;
    background-color: #F28827;
    width: 70%;
    padding-bottom: 20px;
}

.contact-us a {
    font-size: 20px;
    color: white;
}

.margin-left {
    margin-left: 135px;
}

.header .fa {
    font-size: 25px;
}

.btn-rounded {
    border: 2px solid #F28827;
    border-radius: 160px;
    padding: 15px 124px;
}

.launch-project {
    height: 150px;
}

.launch-project a {
    color: #F3993B;
    font-size: 25px;
}

.launch-project h1 {
    color: #F3993B;
    margin-bottom: 30px;
}

.grid-first {
    margin-left: 50px;
}

.grid-footer a {
    color: white;
}

.grid-footer {
    background-color: #f3993b;
}

@media screen and (max-width: 768px) {
    .grid-item {
        float: left;
        width: 100%;
        margin-left: 0;
        margin-bottom: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        text-align: center;
        img{
            width:100%;
        }
    }

    .grid-first {
        margin-left: 0;
    }

    nav {
        display: none;
    }

    #portfolio{
        padding-bottom: 150px;
    }

    .btn-rounded {
        border: 1px solid #F28827;
        border-radius: 160px;
        padding: 15px 20px;
    }

    .launch-project a {
        color: #F3993B;
        font-size: 15px;
    }

    .contact-us a {
        font-size: 10px;
        color: white;
    }

    .header h1 {
        font-size: 20px;
    }

    .margin-left {
        margin-left: 38px;
    }
}

@media screen and (max-width: 480px) {
    .grid-item {
        float: left;
        width: 100%;
        margin-left: 0;
        margin-bottom: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        text-align: center;
        img{
            width:100%;
        }
    }

    .grid-first {
        margin-left: 0;
    }

    nav {
        display: none;
    }

    #portfolio{
        padding-bottom: 150px;
    }

    .btn-rounded {
        border: 1px solid #F28827;
        border-radius: 160px;
        padding: 15px 20px;
    }

    .launch-project a {
        color: #F3993B;
        font-size: 15px;
    }

    .contact-us a {
        font-size: 10px;
        color: white;
    }

    .header h1 {
        font: 12px;
    }

    .margin-left {
        margin-left: 0;
    }
}

.slider{
    background: transparent;
    height:0px;
    width:100%;
    position:absolute;
    left:-0px;
    bottom: 0px;
    visibility: hidden;
}
.open{
    height:40% !important;
    visibility: visible !important;
    background-image: linear-gradient(to top, #1e1e1e, rgba(239,239,239,0)) !important;


}
.transition{
    overflow-y: hidden;
    transition: all .75s ease;
}
.transition-content{
    padding-top: 30%;
    color: white;
}

.transition-links {
    padding-top: 10%;
    margin: 0 auto;
}

.tranistion-links a{
    color: white;
    padding-top: 20px;
    text-align: center;
}
